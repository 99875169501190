.mainContainer {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow: scroll;
  background-color: #ffffff;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
}

.header {
  text-align: center;
  font-size: 64px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}

.bio {
  text-align: center;
  color: rgb(0, 0, 0);
  margin-top: 16px;
  font-size: 20px;
}

.speedButton {
  cursor: pointer;
  margin-top: 16px;
  padding: 8px;
  border: 0;
  border-radius: 10px;
}